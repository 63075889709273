




















































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import rules from "@/components/account/rules";
import moment from "moment";

export default Vue.extend({
  name: "ma-time-picker",
  props: {
    value: String,
    label: String,
    timePickerProps: Object,
  },
  data() {
    return {
      menu: false,
      mValue: "",
      rules,
    };
  },
  computed: {
    textFieldValue(): string {
      const value = moment(this.mValue, "hh:mm");

      if (value.isValid() == false) return "";
      return value.format("hh:mm a");
    },
  },
  watch: {
    value(val: string) {
      this.mValue = val;
    },
    menu(show: boolean) {
      if (show == false) {
        this.saveMenu();
      }
    },
  },
  methods: {
    saveMenu() {
      (this as any).$refs.menu.save(this.mValue);
      this.$emit("input", this.mValue);
    },
  },
});
